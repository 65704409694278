// Core
import { useState, useEffect, useRef } from "react";

// Components
import CustomSelect from "react-select";
import Select from "../common/Select";
import { Link, useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

// Icons
import LookingFor from "../icons/LookingFor";

// Hooks
import { useInvalidFields } from "../../hooks/custom/useInvalidFields";
import { useForms } from "../../hooks/useForms";
import { useLists } from "../../hooks/useLists";
import { usePayments } from "../../hooks/usePayments";
import { useUi } from "../../hooks/useUi";
import { useUser } from "../../hooks/useUser";
import { useModals } from "../../hooks/useModals";

// Instruments
import {
    getAppTitle,
    isFullNameValid,
    EMAIL_REGEX,
    PHONE_REGEX,
    getCurrencySign,
    isEpiic,
    getCurrencyCode
} from "../../helpers/helpers";
import {
    currencyList,
    billingIntervals,
    hourlyDesignPlansNames,
    designPlansNames,
    writingPlansNames,
    bloggingPlansNames,
    webbuildingPlansNames,
    levelNames,
    assistantsPlansNames,
    bundlesNames
} from "../../helpers/constants";
import { domainGAC, epiic, fbPixelId, isStage } from "../../helpers/api";
import { isEmpty, isNil, includes, replace } from "ramda";
import moment from 'moment/moment';
const Url = require('url-parse');

export const CompanyNew = () => {
    /* Ref */
    const emailRef = useRef(null);
    const referralCodeRef = useRef(null);

    /* State */
    const [agreement, setAgreement] = useState(true);
    const [action, setAction] = useState(null);

    /* Hooks */
    const { invalidFields, addField, removeField, setInvalidFields } = useInvalidFields();
    const {
        companyNew: {
            full_name,
            business_name,
            email,
            password,
            phone,
            country,
            currency,
            vat,
            type,
            role,
            industry,
            referral_code,
            challenge,
        },
        updateFormState,
    } = useForms();
    const { staticData: { countries, company: { roles, types, industries }, rates: ratesData, bundles, subscriptions } } = useLists();
    const { setPaymentsState } = usePayments();
    const { isAuth, isEmailExist, isReferralCodeValid, setUiState, validateReferralCodeAsync } = useUi()
    const { company, ipDetails, details, setUserState, fetchUserDetailsAsync, createCompanyAsync, checkEmailExistAsync } = useUser();
    const navigate = useNavigate();


    const updateForm = (obj) => {
        updateFormState('companyNew', obj);
    };

    const getCountry = (countries, ipDetails) => {
        return countries.filter(o => o.code === ipDetails.country_code2).map(o => ({ label: o.name, tax_number: o.tax_number, value: o.code }))[0];
    };

    const saveCurrentSub = (action) => {
        const { currency: accountCurrency } = details;
        const { query } = new Url(window.location.href, true);
        const { type, words, posts, name, amount, level } = query;
        const plan = query.plan ? (includes('plan=double+', window.location.href) ? 'double+' : query.plan).toLowerCase() : null;
        const currencyCode = isAuth ? getCurrencyCode(accountCurrency) : currency;
        let activeSub, activeBundle;

        if (type === 'bundles') {
            setPaymentsState('type', 'bundle');
            if (!isNil(name) && includes(name, bundlesNames)) {
                if (name === 'custom' && amount) {
                    let currentBundle = bundles.filter(o => !!o.active)
                        .filter(o => o.currency === getCurrencySign(currencyCode))
                        .sort((a, b) => b.amount - a.amount)
                        .filter(o => Number(amount) >= o.amount);
                    const discount = isEmpty(currentBundle) || isNil(currentBundle) ? 0 : currentBundle[0].discount;
                    const save = amount * (discount / 100);

                    activeBundle = {
                        amount: Number(amount),
                        currency: getCurrencySign(currencyCode),
                        currency_code: currencyCode,
                        discount,
                        save,
                        name: 'Custom',
                    };
                } else {
                    activeBundle = bundles
                        .filter(o => !!o.active)
                        .filter(o => o.currency === getCurrencySign(currencyCode))
                        .filter(o => o.name.toLowerCase() === name)[0];
                    activeBundle = {
                        ...activeBundle,
                        currency_code: currencyCode
                    };
                }
                if (!isNil(activeBundle)) {
                    setUserState('activeBundle', activeBundle);
                    if (isAuth) {
                        navigate('/payment/confirm');
                    }
                }
            } else {
                if (isAuth) {
                    navigate('/bundles');
                }
            }
        } else if (type === 'bloggers' || type === 'writers') {
            let paymentsType = 'blogging';
            let plansNames = bloggingPlansNames;
            let subs = subscriptions.blogging;
            let activeSubType = 'activeBloggingSub';

            if (type === 'writers') {
                paymentsType = 'writing';
                plansNames = writingPlansNames;
                subs = subscriptions.writing;
                activeSubType = 'activeSub';
            }

            setPaymentsState('type', paymentsType);

            if (!isNil(query['billing-interval'])
                && includes(query['billing-interval'], billingIntervals)
                && !isNil(level)
                && includes(level, levelNames)
                && !isNil(plan)
                && includes(plan, plansNames)) {
                if (plan === 'custom') {
                    if (!isNil(words) && !isNil(posts)) {
                        const word_count = Number(words);
                        const frequency = type === 'writers' ? 1 : Number(posts);
                        const { discount: discountData } = subs.filter(o =>
                            o.currency.toLowerCase() === currencyCode
                            && o.billing_interval_code === query['billing-interval']
                            && !includes('Custom', o.name))[0];
                        const discount = isNil(discountData) ? 1 : (100 - discountData) / 100;

                        let rates = [];
                        rates = ratesData['writing']
                            .filter((item) => item.currency === getCurrencySign(currencyCode))
                            .filter((item) => item.format.includes(('blog')));
                        const clientRate = isEmpty(rates) ? '' : rates.filter((o) => o.level === level)[0].rate_client;
                        const amount = Math.round(clientRate * word_count) * frequency * discount;

                        activeSub = {
                            id: 'custom',
                            name: 'Custom',
                            frequency,
                            word_count,
                            currency: currencyCode.toUpperCase(),
                            amount,
                            level_code: level,
                            billing_interval_code: query['billing-interval'],
                        };
                    }
                } else {
                    activeSub = subs.filter(o =>
                        o.currency.toLowerCase() === currencyCode
                        && o.billing_interval_code === query['billing-interval']
                        && o.name.toLowerCase() === plan
                        && o.level === level)[0];
                }
                if (!isNil(activeSub)) {
                    setUserState(activeSubType, activeSub);
                    if (isAuth) {
                        navigate('/payment/confirm');
                    }
                }
            } else {
                if (isAuth) {
                    navigate(`/${type}/pricing`);
                }
            }
        } else if (type === 'designers' || type === 'motion') {
            setPaymentsState('type', type === 'motion' ? 'motion' : 'design');

            if (!isNil(query['billing-interval'])
                && includes(query['billing-interval'], billingIntervals)
                && !isNil(plan)
                && includes(plan, designPlansNames)) {
                activeSub = subscriptions.design.filter(o =>
                    o.currency.toLowerCase() === currencyCode
                    && o.billing_interval_code === query['billing-interval']
                    && o.name.toLowerCase() === plan)[0];

                setUserState('activeDesignSub', activeSub);

                if (isAuth) {
                    navigate('/payment/confirm');
                }
            } else {
                if (isAuth) {
                    navigate(`/${type === 'motion' ? 'motion' : 'designers'}/pricing`);
                }
            }
        } else if (type === 'hourly-designers') {
            setPaymentsState('type', type);

            if (
                !isNil(query['billing-interval'])
                && includes(query['billing-interval'], billingIntervals)
                && !isNil(plan)
                && includes(plan, hourlyDesignPlansNames)
            ) {
                if (plan.toLowerCase() === 'custom') {
                    activeSub = {
                        id: 'custom',
                        name: 'Custom',
                        currency,
                        amount,
                        hours: getCustomHours(amount, query['billing-interval'], currencyCode),
                        billing_interval_code: query['billing-interval'],
                    };
                } else {
                    activeSub = subscriptions['design_hourly'].filter(o =>
                        o.currency.toLowerCase() === currencyCode
                        && o.billing_interval_code === query['billing-interval']
                        && o.name.toLowerCase() === plan)[0];

                }

                setUserState('activeDesignSub', activeSub);

                if (isAuth) {
                    navigate('/payment/confirm');
                }
            } else {
                if (isAuth) {
                    navigate(`/hourly-designers/pricing`);
                }
            }
        } else if (type === 'website-developers') {
            setPaymentsState('type', 'webbuilding');
            if (!isNil(query['billing-interval'])
                && includes(query['billing-interval'], billingIntervals)
                && !isNil(plan)
                && includes(plan, webbuildingPlansNames)) {
                if (plan === 'custom' && amount) {
                    const { amount: defaultAmount, hours: { middle, senior } } = subscriptions['webbuilding']
                        .filter(o => o.currency.toLowerCase() === currencyCode)
                        .filter(o => o.billing_interval_code === query['billing-interval'])
                        .filter(o => !includes('Custom', o.name))
                        .filter(o => o.name === 'Growth accelerator')[0];
                    const qty = query['billing-interval'] === 'annually' ? 12 : query['billing-interval'] === 'quarterly' ? 3 : 1;
                    const total = Number(amount) * qty;
                    const middleHours = Math.round(amount / (Math.round((defaultAmount / middle) * 100) / 100));
                    const seniorHours = Math.round(amount / (Math.round((defaultAmount / senior) * 100) / 100));

                    activeSub = {
                        id: 'custom',
                        name: 'Custom',
                        currency: currencyCode.toUpperCase(),
                        amount,
                        total,
                        middleHours,
                        seniorHours,
                        billing_interval_code: query['billing-interval'],
                    };
                } else {
                    activeSub = subscriptions['webbuilding'].filter(o =>
                        o.currency.toLowerCase() === currencyCode
                        && o.billing_interval_code === query['billing-interval']
                        && replace(' ', '-', o.name.toLowerCase()) === plan)[0];
                }

                setUserState('activeWebBuildingSub', activeSub);
                if (isAuth) {
                    navigate('/payment/confirm');
                }
            } else {
                if (isAuth) {
                    navigate(`/website-developers/pricing`);
                }
            }
        } else if (type === 'assistants') {
            setPaymentsState('type', 'assistants');
            if (!isNil(query['billing-interval'])
                && includes(query['billing-interval'], billingIntervals)
                && !isNil(plan)
                && includes(plan, assistantsPlansNames)) {
                activeSub = subscriptions['assistants'].filter(o =>
                    o.currency.toLowerCase() === currencyCode
                    && o.billing_interval_code === query['billing-interval']
                    && replace(/\s+/g, '-', o.name.toLowerCase()) === plan)[0];

                setUserState('activeAssistantsSub', activeSub);
                if (isAuth) {
                    navigate('/payment/confirm');
                }
            } else {
                if (isAuth) {
                    navigate(`/assistants/pricing`);
                }
            }
        } else if (isAuth) {
            navigate('/calendar');
        }
        if (!isNil(action) && action === 'go_to_login') {
            navigate('/login');
        }
    };
    const { setModal } = useModals();

    function getCustomHours() {
        const { query } = new Url(window.location.href, true);
        const { amount } = query;
        const interval = query['billing-interval'];

        const { custom_rate: { design: designRate, motion: motionRate } } = subscriptions['design_hourly']
            .filter(o => o.currency === currency.toUpperCase())
            .filter(o => o.billing_interval_code === interval)
            .filter(o => !includes('Custom', o.name))[0];

        const hours = { design: '', motion: '' };

        if (!isEmpty(amount)) {
            const designHours = Math.round((Number(amount) / designRate) / 4.3);
            const motionHours = Math.round((Number(amount) / motionRate) / 4.3);

            hours['design'] = designHours;
            hours['motion'] = motionHours;
        }

        return hours;
    };

    /* Fields */
    const updateInvalidFields = (field) => {
        if (!includes(field, invalidFields)) return;

        removeField(field);
    };
    const onFieldChange = ({ currentTarget: { id, value } }) => {
        let data = value;

        if (id === 'email') {
            if (!isNil(isEmailExist)) setUiState('isEmailExist', null);
            data = data.trim();
        }
        if (id === 'password') {
            data = data.trim();
        }
        if (id === 'phone') {
            const phoneNumberRegex = /[^+\d]/g;
            data = data.replace(phoneNumberRegex, '');
        }
        if (id === 'referral_code') {
            data = data.replace(/[^a-zA-Z0-9]/g, '');
            if (!isNil(isReferralCodeValid)) setUiState('isReferralCodeValid', null);
        }
        if (id === 'challenge') {
            data = data.replace(/ {2,}/g, ' ');
        }

        updateInvalidFields(id);
        updateForm({ [id]: data });
    };
    const onRoleChange = (prop, value) => {
        updateInvalidFields(prop);
        updateForm({ [prop]: value });
    }
    const onCurrencyChange = (prop, value) => {
        updateInvalidFields(prop);
        updateForm({ [prop]: value });
    }
    const onCountryChange = (value) => {
        updateInvalidFields('country');
        updateForm({ country: value, vat: value.tax_number === 'eu' ? vat : '' });
    };
    const onAgreementChange = () => {
        setAgreement(!agreement);

        removeField('agreement');
    };
    const onInputKeyPress = ({ key }) => {
        if (key === 'Enter') {
            onSubmit();
        }
    };
    const goToLogin = (e) => {
        e.preventDefault();
        saveCurrentSub('go_to_login');
    };
    const onReferralCodeBlur = () => {
        if (!isEmpty(referral_code)) {
            validateReferralCodeAsync(referral_code);
        }
    };
    const onIndustryChange = (parameter, value, _, title) => {
        let data = [];
        if (industry.some(o => `${o.value}` === value)) {
            data = industry.filter(o => `${o.value}` !== value);
        } else {
            data = [...industry, { value, title }];
        }
        updateInvalidFields('industry');
        updateForm({ industry: data, });
    };

    /* Submit */
    const checkEmailExist = () => {
        if (isEmailExist) return;

        if (EMAIL_REGEX.test(email.toLowerCase())) {
            checkEmailExistAsync(email);
        } else {
            addField('email');
        }
    };
    const validateField = ({ currentTarget: { id } }) => {
        if (id === 'full_name') {
            if (!isFullNameValid(full_name)) {
                addField(id);
            }
        } else if (id === 'email') {
            if (!(EMAIL_REGEX.test(email.toLowerCase()))) {
                addField(id);
            }
        } else if (id === 'password') {
            if (password.length < 8) {
                addField(id);
            }
        } else if (id === 'business_name') {
            if (business_name.length < 2) {
                addField(id);
            }
        } else if (id === 'phone') {
            if (!(PHONE_REGEX.test(phone))) {
                addField(id);
            }
        }
    };
    const validateFields = () => {
        let fields = [];

        if (!isFullNameValid(full_name)) {
            fields.push('full_name');
        }
        if (!(EMAIL_REGEX.test(email.toLowerCase()))) {
            fields.push('email');
        }
        if (!(PHONE_REGEX.test(phone))) {
            fields.push('phone');
        }
        if (password.length < 8) {
            fields.push('password');
        }
        if (business_name.length < 2) {
            fields.push('business_name');
        }
        if (isNil(country)) {
            fields.push('country');
        }
        if (isEmpty(currency)) {
            fields.push('currency');
        }
        if (isEmpty(role)) {
            fields.push('role');
        }
        if (isEmpty(type)) {
            fields.push('type');
        }
        if (isEmpty(industry)) {
            fields.push('industry');
        }
        if (!isEmpty(referral_code) && (isNil(isReferralCodeValid) || !isReferralCodeValid)) {
            fields.push('referral_code');
        }
        if (!agreement) {
            fields.push('agreement');
        }
        if (isEmpty(challenge.replace(/ +/g, ''))) {
            fields.push('challenge');
        }

        return fields;
    };
    const onSubmit = () => {
        const fields = validateFields();

        setInvalidFields(fields);
        if (isNil(isEmailExist) || (isNil(isReferralCodeValid) && !isEmpty(referral_code))) {
            setAction('submit');
        } else if (!isEmailExist) {
            if (isEmpty(fields)) {
                saveCurrentSub();

                const nameArray = full_name.split(' ');
                const user_first_name = nameArray.splice(0, 1)[0];
                const timezone = moment().format('Z');
                const company = {
                    user_first_name,
                    user_last_name: nameArray.join(' '),
                    user_email: email,
                    user_password: password,
                    user_timezone: timezone,
                    company_name: business_name,
                    country_code: country.value,
                    currency: getCurrencySign(currency),
                    phone,
                    content_budget: 'L',
                    billing_company_name: business_name,
                    billing_country_code: country.value,
                    billing_email: email,
                    billing_tax_number: vat,
                    account_name: 'Untitled account',
                    account_primary_contact: full_name,
                    account_primary_contact_timezone: timezone,
                    account_billing_email: email,
                    account_billing_contact_person: full_name,
                    account_billing_company_name: business_name,
                    account_billing_country_code: country.value,
                    type,
                    role,
                    referral_code,
                    challenge,
                    industry: JSON.stringify(industry.map(o => o.value)),
                };

                createCompanyAsync(company, navigate);
            }
        }
    };

    useEffect(() => {
        if (!isStage) ReactPixel.init(fbPixelId);

        if (isAuth) {
            fetchUserDetailsAsync();
        } else {
            document.title = getAppTitle('New company', company);
            document.body.classList.add('gac-body-company-new');
        }

        const { query } = new Url(window.location.href, true);

        if (!isNil(query.currency) && !isEmpty(query.currency) && currencyList.some(o => o.value === query.currency)) {
            updateForm({ currency: query.currency });
        }
        if (!isNil(query.email) && !isEmpty(query.email)) {
            updateForm({ email: decodeURIComponent(query.email) });
            emailRef.current.focus();
        }
        if (!isNil(query['referral-code']) && !isEmpty(query['referral-code'])) {
            updateForm({ referral_code: query['referral-code'] });
            referralCodeRef.current.focus();
        }

        if (!isEmpty(ipDetails) && !isEmpty(countries)) {
            updateForm({ country: getCountry(countries, ipDetails) });
        }

        if (!isEmpty(email)) {
            checkEmailExist();
        }

        if (!isNil(query['phone']) && !isEmpty(query['phone'])) {
            updateForm({ phone: query['phone'] });
        }

        if (!isNil(query['full_name']) && !isEmpty(query['full_name'])) {
            updateForm({ full_name: query['full_name'] });
        }

        return () => {
            setUiState('isEmailExist', null);
            setUiState('isReferralCodeValid', null);
            if (!isAuth) {
                document.body.classList.remove('gac-body-company-new');
            }
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(ipDetails) && !isEmpty(countries)) {
            updateForm({ country: getCountry(countries, ipDetails) });
        }
    }, [ipDetails]);

    useEffect(() => {
        if (!isNil(isEmailExist) && action === 'submit') {
            if (isEmailExist) {
                setAction(null);
            } else {
                onSubmit();
            }
        }
    }, [isEmailExist]);

    useEffect(() => {
        if (!isNil(isReferralCodeValid) && action === 'submit') {
            if (isReferralCodeValid) {
                onSubmit();
            } else {
                setAction(null);
            }
        }
    }, [isReferralCodeValid]);

    useEffect(() => {
        if (!isEmpty(details) && isAuth) {
            saveCurrentSub();
        }
    }, [details]);

    useEffect(() => {
        const { query } = new Url(window.location.href, true);
        if(!isNil(query.login) && !isAuth) {
            saveCurrentSub();
            setUiState('showLoginDialog', true);
            setModal('createAccount');
        }
    }, []);
    
    if (isAuth) {
        return null;
    }

    const countriesData = countries.filter(o => o.code !== 'IN').map(o => ({ label: o.name, value: o.code, tax_number: o.tax_number }));
    const typesData = types.filter(o => !!o.active).sort((a, b) => a.sort - b.sort).map(o => ({ value: `${o.id}`, title: o.name }));
    const rolesData = roles.filter(o => !!o.active).sort((a, b) => a.sort - b.sort).map(o => ({ value: `${o.id}`, title: o.name }));
    const industriesData = industries.filter(o => !!o.active).sort((a, b) => a.sort - b.sort).map(o => ({ value: `${o.id}`, title: o.name }));

    return <div className="gac-company-new-page">
        <div className="gac-company-new-page__title">First, let’s create a <span>new account</span></div>
        <div className="gac-company-new-form">
            <div className="gac-company-new-form__row">
                <div className='gac-company-new-form__label'>Full name</div>
                <input
                    data-hj-whitelist
                    className={invalidFields.includes('full_name') ? 'gac-invalid' : ''}
                    id='full_name'
                    type='text'
                    value={full_name}
                    onKeyPress={onInputKeyPress}
                    onBlur={validateField}
                    onChange={onFieldChange} />
                {invalidFields.includes('full_name') && <div className='gac-error'>Please enter your full name</div>}
            </div>
            <div className="gac-company-new-form__row">
                <div className='gac-company-new-form__label'>Business name</div>
                <input
                    data-hj-whitelist
                    className={invalidFields.includes('business_name') ? 'gac-invalid' : ''}
                    id='business_name'
                    type='text'
                    value={business_name}
                    onKeyPress={onInputKeyPress}
                    onBlur={validateField}
                    onChange={onFieldChange} />
                {invalidFields.includes('business_name') && <div className='gac-error'>Please enter your business name</div>}
            </div>
            <div className="gac-company-new-form__row">
                <div className='gac-company-new-form__label'>Email</div>
                <input
                    data-hj-whitelist
                    className={invalidFields.includes('email') || isEmailExist ? 'gac-invalid' : ''}
                    id='email'
                    type='text'
                    value={email}
                    ref={emailRef}
                    onKeyPress={onInputKeyPress}
                    onBlur={checkEmailExist}
                    onChange={onFieldChange} />
                {invalidFields.includes('email') && <div className='gac-error'>Please enter a valid email</div>}
                {isEmailExist && <div className='gac-error'>Email address is already registered.</div>}
            </div>
            <div className="gac-company-new-form__row">
                <div className='gac-company-new-form__label'>Password</div>
                <input
                    data-hj-whitelist
                    className={invalidFields.includes('password') ? 'gac-invalid' : ''}
                    id='password'
                    type='password'
                    value={password}
                    onKeyPress={onInputKeyPress}
                    onBlur={validateField}
                    onChange={onFieldChange} />
                {invalidFields.includes('password') && <div className='gac-error'>Minimum password length is 8 characters</div>}
            </div>
            <div className="gac-company-new-form__row">
                <div className='gac-company-new-form__label'>Phone</div>
                <input
                    data-hj-whitelist
                    className={invalidFields.includes('phone') ? 'gac-invalid' : ''}
                    id='phone'
                    type='text'
                    value={phone}
                    onBlur={validateField}
                    onChange={onFieldChange} />
                {invalidFields.includes('phone') && <div className='gac-error'>Please enter a valid phone number</div>}
            </div>
            <div className="gac-company-new-form__row gac-country-row">
                <div>
                    <div className='gac-company-new-form__label'>Country</div>
                    <div className={`gac-custom-select ${invalidFields.includes('country') ? 'gac-invalid' : ''}`}>
                        <CustomSelect
                            placeholder=''
                            classNamePrefix='gac-select'
                            value={country}
                            //TODO react-select menuIsOpen = { true }
                            onChange={onCountryChange}
                            options={countriesData} />
                    </div>
                </div>
                <div>
                    <div className='gac-company-new-form__label'>Currency</div>
                    <Select
                        isMultiple={false}
                        items={currencyList.map(o => ({ value: o.value, title: o.title }))}
                        value={currency}
                        title={currency ? currencyList.filter(o => o.value === currency)[0].title : ''}
                        parameter='currency'
                        invalid={invalidFields.includes('currency')}
                        onChange={onCurrencyChange} />
                </div>
            </div>
            <div className="gac-company-new-form__row gac-company-row">
                <div>
                    <div className='gac-company-new-form__label'>Company type</div>
                    <Select
                        isMultiple={false}
                        items={typesData}
                        value={type}
                        title={type ? types.filter(o => `${o.id}` === type)[0].name : ''}
                        parameter='type'
                        invalid={invalidFields.includes('type')}
                        onChange={onRoleChange} />
                </div>
                <div>
                    <div className='gac-company-new-form__label'>Role</div>
                    <Select
                        isMultiple={false}
                        items={rolesData}
                        value={role}
                        title={role ? roles.filter(o => `${o.id}` === role)[0].name : ''}
                        parameter='role'
                        invalid={invalidFields.includes('role')}
                        onChange={onRoleChange} />
                </div>
            </div>
            <div className="gac-company-new-form__row gac-industry-row">
                <div>
                    <div className='gac-company-new-form__label'>Industry</div>
                    <Select
                        isSearch={true}
                        isMultiple={true}
                        items={industriesData}
                        value={industry.map(o => o.value)}
                        title={industry.length > 1 ? industry.map(o => `${o.title}...`).filter((o, i) => i === 0) : industry.map(o => o.title)}
                        parameter='industry'
                        invalid={invalidFields.includes('industry')}
                        onChange={onIndustryChange} />
                </div>
{/*                <div style={{ position: 'relative' }}>
                    <div className='gac-company-new-form__label'>Referral code</div>
                    <input
                        data-hj-whitelist
                        className=''
                        id='referral_code'
                        className={`gac-company-new-form__referral-code ${!isNil(isReferralCodeValid) && !isReferralCodeValid ? 'gac-invalid' : ''}`}
                        type='text'
                        ref={referralCodeRef}
                        value={referral_code}
                        onBlur={onReferralCodeBlur}
                        onChange={onFieldChange} />
                    {!isNil(isReferralCodeValid) && !isReferralCodeValid && <div className='gac-error'>Please enter a valid referral code</div>}
                </div>*/}
            </div>
        </div>
        <div className="gac-company-new-textarea">
            <div className='gac-company-new-textarea__label'>What’s the biggest challenge in your business right now?</div>
            <textarea
                className={invalidFields.includes('challenge') ? 'gac-invalid' : ''}
                id='challenge'
                value={challenge}
                onChange={onFieldChange} />
        </div>
        <div className='gac-company-new-page__agreement'>
            <input id='agreement' type='checkbox' onChange={onAgreementChange} checked={agreement} />
            <label className={invalidFields.includes('agreement') ? 'gac-invalid' : ''} htmlFor='agreement' />
            I agree with the
            <a rel="noopener noreferrer" target='_blank' href={`https://${isEpiic(null) ? epiic : domainGAC}.com/terms-conditions`}>Terms of use</a>
            and
            <a target='_blank' rel="noopener noreferrer" href={`https://${isEpiic(null) ? epiic : domainGAC}.com/privacy-policy`}>Privacy policy</a>
        </div>
        <div className="gac-company-new-page__btns">
            <span style={{ minWidth: '232px' }} onClick={onSubmit} className='gac-button'>Create an account</span>
        </div>
        <div className="gac-company-new-page__login">
            Already have an account? Great! <br />
            <Link to='/login' onClick={goToLogin}>Log in</Link>
        </div>
        <div className={`gac-company-new-page__apply ${isEpiic(null) ? 'host-epiic' : ''}`}>
            <div><LookingFor /></div>
            <a href={`https://${epiic}.com/careers`} target='_blank' rel='noopener noreferrer'>Apply here</a>
        </div>
        <div className="gac-company-new-page__safe"><i /> Your private information is safe with us</div>
    </div>;
};